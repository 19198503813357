<template>
  <div class="ViewingHistory">
    <van-sticky>
      <van-nav-bar title="History" left-arrow @click-left="onClickLeft" @click-right="onClickRight"
        class="retreat">
        <template #right>
          <van-icon :name="topNav" size="24" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div>
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <div :class="topNavIsok ? 'groupsActive' : 'groups'">
          <van-checkbox :name="item.vod_id" v-for="item in historys" :key="item.vod_id" :label-disabled="true">
            <div @click="toPlays(item.vod_id)" style="display: flex; align-items: center;">
              <div class="imgs">
                <img :src="item.vod_pic" alt="">
              </div>
              <div class="listTitle">
                <div class="van-ellipsis">{{ item.vod_name }}</div>
                <div>Restante</div>
              </div>
            </div>

          </van-checkbox>
        </div>
      </van-checkbox-group>
    </div>
    <div class="foot" v-if="topNavIsok">
      <div class="foot-left" @click="checkAll">Selected All</div>
      <div>|</div>
      <div class="foot-right" @click="remove">Cancel</div>
    </div>
  </div>
</template>
<script>
import { getHistory, remove } from '@/api/Mue';
export default {
  name: '',
  components: {},
  data() {
    return {
      historys: [],
      time: null,
      topNav: 'edit', //顶部右侧图标控制
      topNavIsok: false,
      result: [],
      check: false, //控制全选和反选
    }
  },
  computed: {
    full() {
      return this.getTime()
    }
  },
  mounted() {
    this.getHistoryInfo()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickRight() {
      if (this.topNavIsok) {
        this.topNav = 'edit'
        this.topNavIsok = !this.topNavIsok
      } else {
        this.topNav = 'cross'
        this.topNavIsok = !this.topNavIsok
      }

    },
    getHistoryInfo() {
      const list = {
        pn: 1
      }
      getHistory(list).then(res => {
        this.historys = res.result
      })
    },
    // 处理时间
    getTime(vod_time, view_time) {
      let time = vod_time - view_time
      let hours = this.times(Math.floor(time / 3600));
      time %= 3600;
      let minutes = this.times(Math.floor(time / 60));
      time %= 60;
      this.time = hours + ':' + minutes + ':' + this.times(time)
      return this.time
    },
    //时分秒补零
    times(time) {
      return time < 10 ? '0' + time : time
    },
    // 点击全选和反选
    checkAll() {
      if (this.check) {
        this.$refs.checkboxGroup.toggleAll();
        this.check = false;
      } else {
        this.$refs.checkboxGroup.toggleAll(true);
        this.check = true;
      }

    },
    // 点击删除
    remove() {
      if (this.result.length > 1) {
        const vod_ids = this.result.join(',')
        remove({ vod_ids: vod_ids }).then(
          this.getHistoryInfo()
        )
      } else {
        remove({ vod_ids: this.result[0] }).then(
          this.getHistoryInfo()
        )
      }
    },
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.groups {
  transform: translateX(-47px);
}

.groupsActive {
  font-family: PingFang SC;
}

/deep/.van-checkbox {
  margin-left: 13px;
}

/deep/.van-checkbox__label {
  display: flex;
  align-items: center;
}

.imgs img {
  width: 25vw;
  height: 18vh;
  border-radius: 4px;
  padding-left: 8px;
}

.listTitle {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--title-color);
  padding-left: 10px;
}

.listTitle>div:first-child {
  width: 59vw;
  font-size: 16px;
  padding-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listTitle>div:last-child {
  color: #ef233c;
}

.van-hairline--bottom:after {
  border-bottom-color: #323745;
}

/deep/.van-nav-bar .van-icon {
  opacity: 0.8;
  font-size: 18px;
}

/deep/.van-nav-bar__title {
  opacity: 1;
  font-size: 18px;
  font-weight: bold;
}

.foot {
  width: 100%;
  background-color: #666666;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: var(--title-color);
  padding: 20px 0;
}

.foot-left {
  width: 200px;
  text-align: center;
}

.foot-right {
  color: #ef233c;
  width: 200px;
  text-align: center;
}
</style>
